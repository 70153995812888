@import "@/assets/scss/style.scss";

.c-translations {
    height: calc(100% - 60px) !important;
    background: #ffffff;
    &__section {
        margin-bottom: 12px;
    }

    &__layout {
        height: 100% !important;
    }

    &__heading {
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
    }

    li {
        cursor: pointer;
    }

    .ant-menu-submenu {
        &:first-child {
            margin-top: 30px;
        }
    }

    .right-arrow {
        float: right;
        margin: 0;
    }

    &__content {
        background-color: #fff;
        z-index: 1;
        min-width: fit-content;
    }

    &__layout &__color-menu-item {
        &:first-child {
            margin-top: 0;
        }
    }
}

.ant-menu-inline .ant-menu-item::after {
    border-right: none;
}
.ant-layout-sider {
    background: #ffffff !important;
}
.ant-menu-vertical {
    border-right: 1px #e8e8e8 solid;
}
.ant-menu-root.ant-menu-inline {
    padding: 0 28px;
    height: 100%;
}

.collapse-arrow {
    font-size: 24px;
    width: 80px;
    border-radius: 5px;
    background-color: #1b1b69;
    padding: 5px;
    color: white;
    margin: 20px 0 0 20px;
}

@media (max-width: 992px) {
    .c-translations {
        .ant-menu {
            padding: 0 !important;
        }
    }

    ::v-deep .c-translation {
        padding: 24px 12px !important;
    }
}

@media (max-width: 768px) {
    // .ant-menu-root.ant-menu-inline {
    //     padding: 0;
    // }
}
